export default class Abi {
    static getPresaleFactory() {
        if (process.env.VUE_APP_NETWORK_TYPE == "testnet") {
            return require('./json/DinoPresaleFactoryTestnet.json');
        } else {
            return require('./json/DinoPresaleFactoryMainnet.json');
        }
    }

    static getPresale() {
        if (process.env.VUE_APP_NETWORK_TYPE == "testnet") {
            return require('./json/DinoPresaleTestnet.json');
        } else {
            return require('./json/DinoPresaleMainnet.json');
        }
    }

    static getDino() {
        if (process.env.VUE_APP_NETWORK_TYPE == "testnet") {
            return require('./json/DinoTokenTestnet.json');
        } else {
            return require('./json/DinoTokenMainnet.json');
        }
    }
}