<template>
    <div id="Footer">
        <!-- <footer class="xs-footer-section fundpress-footer-section v2 fixed-bottom" style="background-color: #C02838" v-if="$isMobile()">
            <div class="row col-12">
                <div class="col-12">
                    <p class="mt-3 text-white">2021 - Blocksafuswap Whitelist</p>
                </div>
            </div>
        </footer>
        <footer class="xs-footer-section fundpress-footer-section v3" v-else>
        <div class="fundpress-footer-top-layer">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 col-md-6">
                        <div class="fundpress-single-footer">
                            <div class="xs-footer-logo">
                                <a href="index.html">
                                    <img src="/images/logo_new.png" style="max-height: 100px;" alt="">
                                </a>
                            </div>
                            <div class="fundpress-footer-content">
                                <p>Blocksafuswap Whitelist Competition.</p>
                                <p>- Never Dump because of, BlocksafuSwap</p>
                            </div>
                            <ul class="xs-social-list fundpress-social-list">
                                <li><a target="_blank" href="https://github.com/BlocksafuSwap" class="color-facebook full-round"><i class="fa fa-github"></i></a></li>
                                <li><a target="_blank" href="https://twitter.com/Blocksafuswap" class="color-twitter full-round"><i class="fa fa-twitter"></i></a></li>
                                <li><a target="_blank" href="https://t.me/Blocksafuswap" class="color-dribbble full-round"><i class="fa fa-telegram"></i></a></li>
                                <li><a target="_blank" href="https://medium.com/@Blocksafuswap" class="color-pinterest full-round"><i class="fa fa-medium"></i></a></li>
                            </ul>
                        </div>
                    </div>
                    
                    <div class="col-md-2">
                        <div class="fundpress-single-footer">
                            <div class="xs-footer-title">
                                <h4 class="color-white">Our Link</h4>
                            </div>
                            <nav class="xs-footer-menu">
                                <ul>
                                    <li><a href="https://swap.Blocksafuswap.com">Swap</a></li>
                                    <li><a href="https://token.Blocksafuswap.com">Token</a></li>
                                    <li><a href="#">Farm <span class="badge bg-danger rounded-pill">Soon</span></a></li>
                                    <li><a href="#">Staking <span class="badge bg-danger rounded-pill">Soon</span></a></li>
                                    <li><a href="https://app.Blocksafuswap.com">Reward</a></li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="xs-footer-bottom-layer fundpress-footer-bottom">
            <div class="container">
                <div class="xs-footer-bottom-wraper">
                    <div class="xs-copyright-text fundpress-copyright-text">
                        <p>2021 - Blocksafuswap Whitelist</p>
                    </div>
                    <div class="xs-back-to-top-wraper">
                        <a href="#" class="xs-back-to-top full-round green-btn xs-back-to-top-btn show-last-pos">
                            <i class="fa fa-angle-up"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        </footer> -->
    </div>
</template>

<script>
// import axios from 'axios';

export default {
    name: "Footer",
    // mounted(){
    //     this.getDataTrending();
    // },
    // methods:{
    //     getDataTrending(){
    //         axios.post('http://alloworigin.com/get?url=https://api.pinksale.finance/api/v1/view', {
    //             headers: {
    //                 "Access-Control-Allow-Origin": "https://www.pinksale.finance",
    //             },
    //             chain_id: 56,
    //             pool_address: "0x31b78f16e408D64017C5309aFB463d7D57c4b50b",
    //         })
    //         .then((res) => {
    //             console.log(res.status)
    //         })
    //     },
    // },
}
</script>