<template>
  <div class="">
    <div v-if="$isMobile()">
      <button
      type="button"
      class="btn mr-1 btn-block btn-success mb-2"
      v-if="!$store.getters.getAccount"
      @click="connect"
    >
      Connect Wallet
    </button>
    </div>
    <div v-else>
    <b-button
      variant="success"
      type="button"
      class="btn btn-block btn-success mb-2"
      v-if="!$store.getters.getAccount"
      @click="connect"
    >
      Connect Wallet
    </b-button>
    </div>
  </div>
</template>

<script>
// import Web3Connect from "web3connect";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Abi from "@/libraries/Abi";
import Web3Modal from "web3modal";
import { BButton } from "bootstrap-vue";
export default {
  components: {
    BButton,
  },
  data() {
    return {
      isConnected: false,
      account: null,
      web3: null,
      web3Modal: null,
      address: null,
      provider: null,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      const rpc = {};
      rpc[process.env.VUE_APP_RPC_CHAIN_ID] = process.env.VUE_APP_RPC_URL;
      const providerOptions = {
        walletconnect: {
          package: WalletConnectProvider,
          options: {
            rpc: rpc,
            network: "binance",
            chainId: process.env.VUE_APP_RPC_CHAIN_ID,
            // Mikko's test key - don't copy as your mileage may vary
            infuraId: "8043bb2cf99347b1bfadfb233c5325c0",
          },
        },
      };
      const web3Modal = new Web3Modal({
        network: "mainnet", // optional
        cacheProvider: false, // optional
        providerOptions, // required,
        theme: "dark",
      });
      // web3Modal.clearCachedProvider();
      this.$nextTick(async () => {
        web3Modal.clearCachedProvider();
        this.$store.commit("setWeb3Modal", web3Modal);
      });
    },
    async connect() {
      try {
        this.$store.dispatch("connect");
      } catch (err) {
        console.error(err);
      }
    },
    onDisconnect() {
      this.web3Connect = null;
      this.isConnected = false;
    },
    async tesDeploy() {
      const web3 = this.$store.getters.getWeb3;
      let abi = Abi.getPresaleFactory();
      const contract = new web3.eth.Contract(
        abi,
        "0xb1f8Ae3D5314A7D30Ef7B386873ae88b8913591F"
      );
      const total = await contract.methods
        .create(1, 110, 1626068664, 1626241464, 1626241464)
        .send({
          from: this.$store.getters.getAccount,
        });
      console.log(total);
    },
  },
};
</script>

<style>
.walletconnect-modal__header img {
  display: none;
}
</style>