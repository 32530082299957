<template>
  <div id="BannerWhitelist">
    <div class="container mt-5 pt-4" v-if="$isMobile()">
      <div
        class="col-lg-12 col-md-12 col-sm-12 col-xs-12 d-flex justify-content-center pb-5 mx-sm-0 mx-md-0"
      >
        <div
          class="col-lg-8 col-md-12 col-sm-12 col-xs-12 card py-5 px-lg-5 px-sm-0 px-md-0"
        >
          <img
            class="card-img-top"
            src="/images/background/26.jpg"
            style="border-radius: 5px !important"
            alt="Card image cap"
          />
          <div class="card-body text-center">
            <h4 class="font-weight-bold">Dino Dapps Whitelist</h4>
            <p class="mt-4 font-weight-bold text-dark">
              Quick Information About Project
            </p>
            <p class="my-0">Doxxed ✅</p>
            <p class="my-0">Staking Reward ✅</p>
            <p class="my-0">Long Term Project ✅</p>
            <p class="my-0">Audit By Blocksafu ✅</p>
            <p class="my-0">dApps, NFT, Token Tracker Platform ✅</p>
            <p class="my-0">Great Smart Contract ✅</p>
            <p class="my-0">List On Pink Sale ✅</p>
            <p class="my-0">Safety Project Anti Rug Pull ✅</p>
            <p class="my-0">Nice Roadmap and trusted team ✅</p>
            <p class="font-weight-bold text-dark">
              Become Dino Dapps Whitelist
            </p>
            <p class="my-0">
              * Website:
              <a href="https://dinodapps.com" target="_blank"
                >https://dinodapps.com</a
              >
            </p>
            <p class="my-0">
              * Telegram Group Chat:
              <a href="https://t.me/DinoDappsOfficial" target="_blank"
                >https://t.me/DinoDappsOfficial</a
              >
            </p>
            <p class="my-0">
              * Telegram Group Announcement:
              <a href="https://t.me/dinodappsannouncement"
                >https://t.me/dinodappsannouncement</a
              >
            </p>
            <p class="my-0">
              * Twitter:
              <a href="https://twitter.com/DinoDapps" target="_blank"
                >https://twitter.com/DinoDapps</a
              >
            </p>
            <p class="my-0">
              * Youtube:
              <a href="https://youtube.com/@DinoDappsOfficial" target="_blank"
                >https://youtube.com/@DinoDappsOfficial</a
              >
            </p>
            <p class="my-0">
              * Tokenomics:
              <a href="https://token.dinodapps.com/token" target="_blank"
                >https://token.dinodapps.com/token</a
              >
            </p>
            <p class="mt-0">
              * Whitepaper:
              <a href="https://token.dinodapps.com/documents" target="_blank"
                >https://token.dinodapps.com/documents</a
              >
            </p>
            <hr />
            <p class="text-dark font-weight-bold">
              How to participate Dino Dapps Whitelist Competition (Quick Guide)
            </p>
            <hr />
            <p class="my-0 text-left text-dark">
              1. Open Chrome, Safari, Or Firefox Browser
            </p>
            <p class="my-0 text-left text-dark">
              2. Open this link, and Click connect wallet
            </p>
            <p class="my-0 text-left text-dark">
              3. Select your wallet apps, and connect wallet account (trust
              wallet, or another)
            </p>
            <p class="my-0 text-left text-dark">
              4. After connect you can see task in the list below
            </p>
            <p class="my-0 text-left text-dark">
              5. Finish the task step by step
            </p>
            <p class="my-0 text-left text-dark">
              6. Click Button <b>'Mark as Complete'</b> for finish the task step
              by step
            </p>
            <p class="my-0 text-left text-dark">
              7. Share referral to get more point
            </p>
            <p class="my-0 text-left text-dark">
              8. Your point, and referral display on Top
            </p>
            <h4 class="py-4">Task</h4>
            <div class="row col-12" v-if="$store.getters.getAccountSimple">
              <div class="col-12">
                <p class="badge badge-primary py-2 text-white">
                  Your Point: {{ this.point }}, ref: {{ this.referralCount }}
                </p>
              </div>
              <div class="col-12">
                <p class="badge badge-primary py-2 text-white">
                  Total Entry: {{ total }}
                </p>
              </div>
              <div class="col-12">
                <p class="badge badge-primary py-2 text-white">
                  Total Participant: {{ entry }}
                </p>
              </div>
            </div>
            <div v-if="$store.getters.getAccountSimple">
              <div class="col-12 text-left card bg-light my-2">
                <div class="card-body">
                  <p class="font-weight-bold text-dark">
                    1. Follow Telegram Channel
                  </p>
                  <form @submit.prevent="addTask1">
                    <label for="">Your Telegram Account</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Example: @ElonMusk"
                    />
                    <input
                      type="text"
                      class="form-control bg-white"
                      v-model="formTask1.upline"
                      hidden
                      placeholder="0x"
                      disabled
                    />
                    <input
                      type="text"
                      class="form-control bg-white"
                      v-model="formTask1.wallet"
                      hidden
                      placeholder="0x"
                      disabled
                    />
                    <a
                      href="https://t.me/dinodappsofficial"
                      target="_blank"
                      class="btn btn-sm btn-success my-3 mr-2"
                      ><i class="fa fa-telegram mr-1"></i> TG Group</a
                    >
                    <copy-to-clipboard
                      text="https://t.me/dinodappsofficial"
                      @copy="handleCopy"
                    >
                      <a class="btn btn-success btn-sm text-white">
                        <i class="fa fa-copy"></i>
                      </a>
                    </copy-to-clipboard>
                    <br />
                    <button
                      type="submit"
                      class="btn btn-sm btn-outline-success my-3"
                    >
                      <i class="fa fa-check mr-1"></i> Mark As Complete
                    </button>
                  </form>
                  <p class="text-left text-dark">
                    Note: After do task, Click Button
                    <b>'Mark as Complete'</b> for finish the task 1
                  </p>
                </div>
              </div>
              <div class="col-12 text-left card bg-light my-2">
                <div class="card-body">
                  <p class="font-weight-bold text-dark">
                    2. Follow Telegram Announcement Channel
                  </p>
                  <form @submit.prevent="addTask2">
                    <label for="">Your Telegram Account</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Example: @ElonMusk"
                    />
                    <input
                      type="text"
                      class="form-control bg-white"
                      v-model="formTask2.upline"
                      hidden
                      placeholder="0x"
                      disabled
                    />
                    <input
                      type="text"
                      class="form-control bg-white"
                      v-model="formTask2.wallet"
                      hidden
                      placeholder="0x"
                      disabled
                    />
                    <a
                      href="https://t.me/dinodappsannouncement"
                      target="_blank"
                      class="btn btn-sm btn-success my-3 mr-2"
                      ><i class="fa fa-telegram mr-1"></i> TG Channel</a
                    >
                    <copy-to-clipboard
                      text="https://t.me/dinodappsannouncement"
                      @copy="handleCopy1"
                    >
                      <a class="btn btn-success btn-sm text-white">
                        <i class="fa fa-copy"></i>
                      </a>
                    </copy-to-clipboard>
                    <br />
                    <button
                      type="submit"
                      class="btn btn-sm btn-outline-success my-3"
                    >
                      <i class="fa fa-check mr-1"></i> Mark As Complete
                    </button>
                  </form>
                  <p class="text-dark font-weight-bold mb-0">
                    Telegram Announcement URL
                  </p>
                  <p class="text-left text-dark">
                    Note: After do task, Click Button
                    <b>'Mark as Complete'</b> for finish the task 2
                  </p>
                </div>
              </div>
              <div class="col-12 text-left card bg-light my-2">
                <div class="card-body">
                  <p class="font-weight-bold text-dark">
                    3. Follow Twitter Dino Dapps
                  </p>
                  <form @submit.prevent="addTask3">
                    <label for="">Your Twitter Account</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Example: ElonMusk@Tesla.com"
                    />
                    <input
                      type="text"
                      class="form-control bg-white"
                      v-model="formTask3.upline"
                      hidden
                      placeholder="0x"
                      disabled
                    />
                    <input
                      type="text"
                      class="form-control bg-white"
                      v-model="formTask3.wallet"
                      hidden
                      placeholder="0x"
                      disabled
                    />
                    <a
                      href="https://twitter.com/dinodapps"
                      target="_blank"
                      class="btn btn-sm btn-success my-3 mr-2"
                      ><i class="fa fa-twitter mr-1"></i> Follow Twitter</a
                    >
                    <copy-to-clipboard
                      text="https://twitter.com/dinodapps"
                      @copy="handleCopy2"
                    >
                      <a class="btn btn-success btn-sm text-white">
                        <i class="fa fa-copy"></i>
                      </a>
                    </copy-to-clipboard>
                    <button
                      type="submit"
                      class="btn btn-sm btn-outline-success my-3"
                    >
                      <i class="fa fa-check mr-1"></i> Mark As Complete
                    </button>
                  </form>
                  <p class="text-left text-dark">
                    Note: After do task, Click Button
                    <b>'Mark as Complete'</b> for finish the task 3
                  </p>
                </div>
              </div>
              <div class="col-12 text-left card bg-light my-2">
                <div class="card-body">
                  <p class="font-weight-bold text-dark">
                    4. Retweet Tweet From Dino Dapps Twitter
                  </p>
                  <form @submit.prevent="addTask4">
                    <label for="">Link Tweet</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="https://twitter.com/dinodapps/status/1465545695717474304"
                    />
                    <input
                      type="text"
                      class="form-control bg-white"
                      v-model="formTask4.upline"
                      hidden
                      placeholder="0x"
                      disabled
                    />
                    <input
                      type="text"
                      class="form-control bg-white"
                      v-model="formTask4.wallet"
                      hidden
                      placeholder="0x"
                      disabled
                    />
                    <a
                      href="https://twitter.com/dinodapps/status/1465545695717474304"
                      target="_blank"
                      class="btn btn-sm btn-success my-3 mr-2"
                      ><i class="fa fa-telegram mr-1"></i> Retweet</a
                    >
                    <copy-to-clipboard
                      text="https://twitter.com/dinodapps/status/1465545695717474304"
                      @copy="handleCopy3"
                    >
                      <a class="btn btn-success btn-sm text-white">
                        <i class="fa fa-copy"></i>
                      </a>
                    </copy-to-clipboard>
                    <br />
                    <button
                      type="submit"
                      class="btn btn-sm btn-outline-success my-3"
                    >
                      <i class="fa fa-check mr-1"></i> Mark As Complete
                    </button>
                  </form>
                  <p class="text-left text-dark">
                    Note: After do task, Click Button
                    <b>'Mark as Complete'</b> for finish the task 4
                  </p>
                </div>
              </div>
              <div class="col-12 text-left card bg-light my-2">
                <div class="card-body">
                  <p class="font-weight-bold text-dark">
                    5. Your Mail for get new information about Dino Dapps
                  </p>
                  <form @submit.prevent="addTask5">
                    <label for="">Your Mail</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="formTask5.email"
                      placeholder="Example: ElonMusk@Tesla.com"
                    />
                    <input
                      type="text"
                      class="form-control bg-white"
                      v-model="formTask5.upline"
                      hidden
                      placeholder="0x"
                      disabled
                    />
                    <input
                      type="text"
                      class="form-control bg-white"
                      v-model="formTask5.wallet"
                      hidden
                      placeholder="0x"
                      disabled
                    />
                    <button
                      type="submit"
                      class="btn btn-sm btn-outline-success my-3"
                    >
                      <i class="fa fa-check mr-1"></i> Mark As Complete
                    </button>
                  </form>
                  <p class="text-left text-dark">
                    Note: After do task, Click Button
                    <b>'Mark as Complete'</b> for finish the task 5
                  </p>
                </div>
              </div>
              <div class="col-12 text-left card bg-light my-2">
                <div class="card-body">
                  <p class="font-weight-bold text-dark">
                    6. Your Allocation Investment
                  </p>
                  <form @submit.prevent="addTask6">
                    <label for="">Your Allocation Investment</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="formTask6.allocation"
                      placeholder="Example: 5 BNB"
                    />
                    <label for="">The reason?</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="formTask6.reason"
                      placeholder="Example: Because Dino Dapps is Awesome and Safety"
                    />
                    <input
                      type="text"
                      class="form-control bg-white"
                      v-model="formTask6.upline"
                      hidden
                      placeholder="0x"
                      disabled
                    />
                    <input
                      type="text"
                      class="form-control bg-white"
                      v-model="formTask6.wallet"
                      hidden
                      placeholder="0x"
                      disabled
                    />
                    <button
                      type="submit"
                      class="btn btn-sm btn-outline-success my-3"
                    >
                      <i class="fa fa-check mr-1"></i> Mark As Complete
                    </button>
                  </form>
                  <p class="text-left text-dark">
                    Note: After input form below, Click Button
                    <b>'Mark as Complete'</b> for finish the task 6
                  </p>
                </div>
              </div>
              <div class="col-12 text-left card bg-light my-2">
                <div class="card-body">
                  <p class="font-weight-bold text-dark">
                    7. Share Your Referral Link
                  </p>
                  <div class="col-12 bg-white py-3">
                    <label for="">Your Referral Link</label>
                    <textarea
                      ref="text"
                      class="form-control"
                      :value="
                        'https://whitelist.dinodapps.com/?ref=' +
                        this.$store.getters.getAccount
                      "
                    >
                    </textarea>
                    <br />
                    <button
                      class="btn btn-outline-success btn-sm"
                      @click="vuecopydemo"
                    >
                      <i class="fa fa-copy"></i> Click Copy
                    </button>
                  </div>
                  <p class="text-left text-dark">
                    Note: Share referral to get more point
                  </p>
                </div>
              </div>
            </div>
            <div class="col-12 pb-4" v-else>
              <connect-wallet-block />
              <button class="btn btn-primary btn-block">
                Please Connect Wallet Before To See Task
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container" v-else>
      <div
        class="col-lg-12 col-md-12 col-sm-12 col-xs-12 d-flex justify-content-center"
      >
        <div
          class="col-lg-8 col-md-12 col-sm-12 col-xs-12 card py-5 px-lg-5 px-sm-0 px-md-0"
        >
          <img
            class="card-img-top"
            src="/images/background/26.jpg"
            style="border-radius: 5px !important"
            alt="Card image cap"
          />
          <div class="card-body text-center pb-4">
            <h4 class="font-weight-bold">Dino Dapps Whitelist</h4>
            <p class="mt-4 font-weight-bold text-dark">
              Quick Information About Project
            </p>
            <p class="my-0">Doxxed ✅</p>
            <p class="my-0">Staking Reward ✅</p>
            <p class="my-0">Long Term Project ✅</p>
            <p class="my-0">Audit By Blocksafu ✅</p>
            <p class="my-0">dApps, NFT, Token Tracker Platform ✅</p>
            <p class="my-0">Great Smart Contract ✅</p>
            <p class="my-0">List On Pink Sale ✅</p>
            <p class="my-0">Safety Project Anti Rug Pull ✅</p>
            <p class="my-0">Nice Roadmap and trusted team ✅</p>
            <p class="font-weight-bold text-dark">
              Become Dino Dapps Whitelist
            </p>
            <p class="my-0">
              * Website:
              <a href="https://dinodapps.com" target="_blank"
                >https://dinodapps.com</a
              >
            </p>
            <p class="my-0">
              * Telegram Group Chat:
              <a href="https://t.me/DinoDappsOfficial" target="_blank"
                >https://t.me/DinoDappsOfficial</a
              >
            </p>
            <p class="my-0">
              * Telegram Group Announcement:
              <a href="https://t.me/dinodappsannouncement"
                >https://t.me/dinodappsannouncement</a
              >
            </p>
            <p class="my-0">
              * Twitter:
              <a href="https://twitter.com/DinoDapps" target="_blank"
                >https://twitter.com/DinoDapps</a
              >
            </p>
            <p class="my-0">
              * Youtube:
              <a href="https://youtube.com/@DinoDappsOfficial" target="_blank"
                >https://youtube.com/@DinoDappsOfficial</a
              >
            </p>
            <p class="my-0">
              * Tokenomics:
              <a href="https://token.dinodapps.com/token" target="_blank"
                >https://token.dinodapps.com/token</a
              >
            </p>
            <p class="mt-0">
              * Whitepaper:
              <a href="https://token.dinodapps.com/documents" target="_blank"
                >https://token.dinodapps.com/documents</a
              >
            </p>
            <hr />
            <p class="text-dark font-weight-bold">
              How to participate Dino Dapps Whitelist Competition (Quick Guide)
            </p>
            <hr />
            <p class="my-0 text-left text-dark">
              1. Open Chrome, Safari, Or Firefox Browser
            </p>
            <p class="my-0 text-left text-dark">
              2. Open this link, and Click connect wallet
            </p>
            <p class="my-0 text-left text-dark">
              3. Select your wallet, and connect
            </p>
            <p class="my-0 text-left text-dark">
              4. After connect you can see task in the list below
            </p>
            <p class="my-0 text-left text-dark">
              5. Finish the task step by step
            </p>
            <p class="my-0 text-left text-dark">
              6. Click Button <b>'Mark as Complete'</b> for finish the task step
              by step
            </p>
            <p class="my-0 text-left text-dark">
              7. Share referral to get more point
            </p>
            <p class="my-0 text-left text-dark">
              8. Your point, and referral display on Top
            </p>
            <h4 class="py-4">Task</h4>
            <div class="row col-12 mb-3" v-if="$store.getters.getAccountSimple">
              <div class="col-4">
                <p class="badge badge-primary py-2 px-4 text-white">
                  Your Point: {{ this.point }}, ref: {{ this.referralCount }}
                </p>
              </div>
              <div class="col-4">
                <p class="badge badge-primary py-2 px-4 text-white">
                  Total Entries: {{ total }}
                </p>
              </div>
              <div class="col-4">
                <p class="badge badge-primary py-2 px-4 text-white">
                  Total All Entries: {{ entry }}
                </p>
              </div>
            </div>
            <div v-if="$store.getters.getAccountSimple">
              <div class="col-12 text-left card bg-light my-2">
                <div class="card-body">
                  <p class="font-weight-bold text-dark">
                    1. Follow Telegram Channel
                  </p>
                  <form @submit.prevent="addTask1">
                    <label for="">Your Telegram Account</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Example: @ElonMusk"
                    />
                    <input
                      type="text"
                      class="form-control bg-white"
                      v-model="formTask1.upline"
                      hidden
                      placeholder="0x"
                      disabled
                    />
                    <input
                      type="text"
                      class="form-control bg-white"
                      v-model="formTask1.wallet"
                      hidden
                      placeholder="0x"
                      disabled
                    />
                    <a
                      href="https://t.me/dinodappsofficial"
                      target="_blank"
                      class="btn btn-sm btn-success my-3 mr-2"
                      ><i class="fa fa-telegram mr-1"></i> TG Group</a
                    >
                    <copy-to-clipboard
                      text="https://t.me/dinodappsofficial"
                      @copy="handleCopy"
                    >
                      <a class="btn btn-success btn-sm text-white">
                        <i class="fa fa-copy"></i>
                      </a>
                    </copy-to-clipboard>
                    <br />
                    <button
                      type="submit"
                      class="btn btn-sm btn-outline-success my-3"
                    >
                      <i class="fa fa-check mr-1"></i> Mark As Complete
                    </button>
                  </form>
                  <p class="text-left text-dark">
                    Note: After do task, Click Button
                    <b>'Mark as Complete'</b> for finish the task 1
                  </p>
                </div>
              </div>
              <div class="col-12 text-left card bg-light my-2">
                <div class="card-body">
                  <p class="font-weight-bold text-dark">
                    2. Follow Telegram Announcement Channel
                  </p>
                  <form @submit.prevent="addTask2">
                    <label for="">Your Telegram Account</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Example: @ElonMusk"
                    />
                    <input
                      type="text"
                      class="form-control bg-white"
                      v-model="formTask2.upline"
                      hidden
                      placeholder="0x"
                      disabled
                    />
                    <input
                      type="text"
                      class="form-control bg-white"
                      v-model="formTask2.wallet"
                      hidden
                      placeholder="0x"
                      disabled
                    />
                    <a
                      href="https://t.me/dinodappsannouncement"
                      target="_blank"
                      class="btn btn-sm btn-success my-3 mr-2"
                      ><i class="fa fa-telegram mr-1"></i> TG Channel</a
                    >
                    <copy-to-clipboard
                      text="https://t.me/dinodappsannouncement"
                      @copy="handleCopy1"
                    >
                      <a class="btn btn-success btn-sm text-white">
                        <i class="fa fa-copy"></i>
                      </a>
                    </copy-to-clipboard>
                    <br />
                    <button
                      type="submit"
                      class="btn btn-sm btn-outline-success my-3"
                    >
                      <i class="fa fa-check mr-1"></i> Mark As Complete
                    </button>
                  </form>
                  <p class="text-dark font-weight-bold mb-0">
                    Telegram Announcement URL
                  </p>
                  <p class="text-left text-dark">
                    Note: After do task, Click Button
                    <b>'Mark as Complete'</b> for finish the task 2
                  </p>
                </div>
              </div>
              <div class="col-12 text-left card bg-light my-2">
                <div class="card-body">
                  <p class="font-weight-bold text-dark">
                    3. Follow Twitter Dino Dapps
                  </p>
                  <form @submit.prevent="addTask3">
                    <label for="">Your Twitter Account</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Example: ElonMusk@Tesla.com"
                    />
                    <input
                      type="text"
                      class="form-control bg-white"
                      v-model="formTask3.upline"
                      hidden
                      placeholder="0x"
                      disabled
                    />
                    <input
                      type="text"
                      class="form-control bg-white"
                      v-model="formTask3.wallet"
                      hidden
                      placeholder="0x"
                      disabled
                    />
                    <a
                      href="https://twitter.com/dinodapps"
                      target="_blank"
                      class="btn btn-sm btn-success my-3 mr-2"
                      ><i class="fa fa-twitter mr-1"></i> Follow Twitter</a
                    >
                    <copy-to-clipboard
                      text="https://twitter.com/dinodapps"
                      @copy="handleCopy2"
                    >
                      <a class="btn btn-success btn-sm text-white">
                        <i class="fa fa-copy"></i>
                      </a>
                    </copy-to-clipboard>
                    <br />
                    <button
                      type="submit"
                      class="btn btn-sm btn-outline-success my-3"
                    >
                      <i class="fa fa-check mr-1"></i> Mark As Complete
                    </button>
                  </form>
                  <p class="text-left text-dark">
                    Note: After do task, Click Button
                    <b>'Mark as Complete'</b> for finish the task 3
                  </p>
                </div>
              </div>
              <div class="col-12 text-left card bg-light my-2">
                <div class="card-body">
                  <p class="font-weight-bold text-dark">
                    4. Retweet Tweet From Dino Dapps Twitter
                  </p>
                  <form @submit.prevent="addTask4">
                    <label for="">Link Tweet</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="https://twitter.com/dinodapps/status/1465545695717474304"
                    />
                    <input
                      type="text"
                      class="form-control bg-white"
                      v-model="formTask4.upline"
                      hidden
                      placeholder="0x"
                      disabled
                    />
                    <input
                      type="text"
                      class="form-control bg-white"
                      v-model="formTask4.wallet"
                      hidden
                      placeholder="0x"
                      disabled
                    />
                    <a
                      href="https://twitter.com/dinodapps/"
                      target="_blank"
                      class="btn btn-sm btn-success my-3 mr-2"
                      ><i class="fa fa-telegram mr-1"></i> Retweet</a
                    >
                    <copy-to-clipboard
                      text="https://twitter.com/"
                      @copy="handleCopy3"
                    >
                      <a class="btn btn-success btn-sm text-white">
                        <i class="fa fa-copy"></i>
                      </a>
                    </copy-to-clipboard>
                    <br />
                    <button
                      type="submit"
                      class="btn btn-sm btn-outline-success my-3"
                    >
                      <i class="fa fa-check mr-1"></i> Mark As Complete
                    </button>
                  </form>
                  <p class="text-left text-dark">
                    Note: After do task, Click Button
                    <b>'Mark as Complete'</b> for finish the task 4
                  </p>
                </div>
              </div>
              <div class="col-12 text-left card bg-light my-2">
                <div class="card-body">
                  <p class="font-weight-bold text-dark">
                    5. Your Mail for get new information about Dino Dapps
                  </p>
                  <form @submit.prevent="addTask5">
                    <label for="">Your Mail</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="formTask5.email"
                      placeholder="Example: ElonMusk@Tesla.com"
                    />
                    <input
                      type="text"
                      class="form-control bg-white"
                      v-model="formTask5.upline"
                      hidden
                      placeholder="0x"
                      disabled
                    />
                    <input
                      type="text"
                      class="form-control bg-white"
                      v-model="formTask5.wallet"
                      hidden
                      placeholder="0x"
                      disabled
                    />
                    <button
                      type="submit"
                      class="btn btn-sm btn-outline-success my-3"
                    >
                      <i class="fa fa-check mr-1"></i> Mark As Complete
                    </button>
                  </form>
                  <p class="text-left text-dark">
                    Note: After do task, Click Button
                    <b>'Mark as Complete'</b> for finish the task 5
                  </p>
                </div>
              </div>
              <div class="col-12 text-left card bg-light my-2">
                <div class="card-body">
                  <p class="font-weight-bold text-dark">
                    6. Your Allocation Investment
                  </p>
                  <form @submit.prevent="addTask6">
                    <label for="">Your Allocation Investment</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="formTask6.allocation"
                      placeholder="Example: 5 BNB"
                    />
                    <label for="">The reason?</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="formTask6.reason"
                      placeholder="Example: Because Dino Dapps is Awesome and Safety"
                    />
                    <input
                      type="text"
                      class="form-control bg-white"
                      v-model="formTask6.upline"
                      hidden
                      placeholder="0x"
                      disabled
                    />
                    <input
                      type="text"
                      class="form-control bg-white"
                      v-model="formTask6.wallet"
                      hidden
                      placeholder="0x"
                      disabled
                    />
                    <button
                      type="submit"
                      class="btn btn-sm btn-outline-success my-3"
                    >
                      <i class="fa fa-check mr-1"></i> Mark As Complete
                    </button>
                  </form>
                  <p class="text-left text-dark">
                    Note: After input form below, Click Button
                    <b>'Mark as Complete'</b> for finish the task 6
                  </p>
                </div>
              </div>
              <div class="col-12 text-left card bg-light my-2">
                <div class="card-body">
                  <p class="font-weight-bold text-dark">
                    7. Share Your Referral Link
                  </p>
                  <div class="col-12 bg-white py-3">
                    <label for="">Your Referral Link</label>
                    <textarea
                      ref="text"
                      class="form-control"
                      :value="
                        'https://whitelist.dinodapps.com/?ref=' + this.refCode
                      "
                    >
                    </textarea>
                    <br />
                    <button
                      class="btn btn-outline-success btn-sm"
                      @click="vuecopydemo"
                    >
                      <i class="fa fa-copy"></i> Click Copy
                    </button>
                  </div>
                  <p class="text-left text-dark">
                    Note: Share referral to get more point
                  </p>
                </div>
              </div>
            </div>
            <div class="col-12 pb-4" v-else>
              <connect-wallet-block />
              <button class="btn btn-primary btn-block">
                Please Connect Wallet Before To See Task
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import { BButtonGroup, BButton} from "bootstrap-vue";
import ConnectWalletBlock from "./ConnectWalletBlock.vue";
import CopyToClipboard from "vue-copy-to-clipboard";
import axios from "axios";
import { mapGetters } from "vuex";
export default {
  name: "BannerWhitelist",
  components: {
    ConnectWalletBlock,
    CopyToClipboard,
  },
  data() {
    return {
      ref: "",
      formTask1: {
        wallet: this.$store.getters.getAccount,
        upline: this.$route.query.ref || "0x",
      },
      formTask2: {
        wallet: this.$store.getters.getAccount,
        upline: this.$route.query.ref || "0x",
      },
      formTask3: {
        wallet: this.$store.getters.getAccount,
        upline: this.$route.query.ref || "0x",
      },
      formTask4: {
        wallet: this.$store.getters.getAccount,
        upline: this.$route.query.ref || "0x",
      },
      formTask5: {
        wallet: this.$store.getters.getAccount,
        upline: this.$route.query.ref || "0x",
        email: "",
      },
      formTask6: {
        wallet: this.$store.getters.getAccount,
        upline: this.$route.query.ref || "0x",
        allocation: "",
        reason: "",
      },
      message: "",
      wallet: this.$store.getters.getAccount,
      point: "",
      total: "",
      entry: "",
      referralCount: "",
      refCode: "",
    };
  },
  computed: {
    ...mapGetters({
      getAccount: "getAccount",
    }),
  },
  mounted() {
    this.ref = this.$route.query.ref;
    this.getTotal();
    this.getEntry();
  },
  watch: {
    getAccount: {
      handler() {
        if (this.$store.getters.getAccount) {
          this.getDataPoint();
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    addTask1() {
      axios
        .post("https://api-wl.dinodapps.com/api/task1", {
          wallet: this.$store.getters.getAccount,
          upline: this.$route.query.ref || "0x",
        })
        .then((res) => {
          this.$toasted.show("Task 1 complete", {
            duration: 5000,
            position: "top-center",
            iconPack: "fontawesome",
            icon: "fa-check",
            type: "success",
          });
          console.log(res.error);
        })
        .catch((err) => {
          this.$toasted.show(err.response.data.error, {
            duration: 5000,
            position: "top-center",
            iconPack: "fontawesome",
            icon: "fa-close",
            type: "danger",
          });
        });
    },
    addTask2() {
      axios
        .post("https://api-wl.dinodapps.com/api/task2", {
          wallet: this.$store.getters.getAccount,
          upline: this.$route.query.ref || "0x",
        })
        .then((res) => {
          this.$toasted.show("Task 2 complete", {
            duration: 5000,
            position: "top-center",
            iconPack: "fontawesome",
            icon: "fa-check",
            type: "success",
          });
          console.log(res.error);
        })
        .catch((err) => {
          this.$toasted.show(err.response.data.error, {
            duration: 5000,
            position: "top-center",
            iconPack: "fontawesome",
            icon: "fa-close",
            type: "danger",
          });
        });
    },
    addTask3() {
      axios
        .post("https://api-wl.dinodapps.com/api/task3", {
          wallet: this.$store.getters.getAccount,
          upline: this.$route.query.ref || "0x",
        })
        .then((res) => {
          this.$toasted.show("Task 3 complete", {
            duration: 5000,
            position: "top-center",
            iconPack: "fontawesome",
            icon: "fa-check",
            type: "success",
          });
          console.log(res.error);
        })
        .catch((err) => {
          this.$toasted.show(err.response.data.error, {
            duration: 5000,
            position: "top-center",
            iconPack: "fontawesome",
            icon: "fa-close",
            type: "danger",
          });
        });
    },
    addTask4() {
      axios
        .post("https://api-wl.dinodapps.com/api/task4", {
          wallet: this.$store.getters.getAccount,
          upline: this.$route.query.ref || "0x",
        })
        .then((res) => {
          this.$toasted.show("Task 4 complete", {
            duration: 5000,
            position: "top-center",
            iconPack: "fontawesome",
            icon: "fa-check",
            type: "success",
          });
          console.log(res.error);
        })
        .catch((err) => {
          this.$toasted.show(err.response.data.error, {
            duration: 5000,
            position: "top-center",
            iconPack: "fontawesome",
            icon: "fa-close",
            type: "danger",
          });
        });
    },
    addTask5() {
      axios
        .post("https://api-wl.dinodapps.com/api/task5", {
          wallet: this.$store.getters.getAccount,
          upline: this.$route.query.ref || "0x",
          email: this.formTask5.email,
        })
        .then((res) => {
          this.$toasted.show("Task 5 complete", {
            duration: 5000,
            position: "top-center",
            iconPack: "fontawesome",
            icon: "fa-check",
            type: "success",
          });
          console.log(res.error);
        })
        .catch((err) => {
          this.$toasted.show(err.response.data.error, {
            duration: 5000,
            position: "top-center",
            iconPack: "fontawesome",
            icon: "fa-close",
            type: "danger",
          });
        });
    },
    addTask6() {
      axios
        .post("https://api-wl.dinodapps.com/api/task6", {
          wallet: this.$store.getters.getAccount,
          upline: this.$route.query.ref || "0x",
          allocation: this.formTask6.allocation,
          reason: this.formTask6.reason,
        })
        .then((res) => {
          this.$toasted.show("Task 6 complete", {
            duration: 5000,
            position: "top-center",
            iconPack: "fontawesome",
            icon: "fa-check",
            type: "success",
          });
          console.log(res.error);
        })
        .catch((err) => {
          this.$toasted.show(err.response.data.error, {
            duration: 5000,
            position: "top-center",
            iconPack: "fontawesome",
            icon: "fa-close",
            type: "danger",
          });
        });
    },
    getDataPoint() {
      axios
        .get("https://api-wl.dinodapps.com/api/data", {
          params: {
            wallet: this.$store.getters.getAccount,
            ref: this.$route.query.ref,
          },
        })
        .then((res) => {
          this.point = res.data.point;
          this.referralCount = res.data.referralCount;
          this.refCode = res.data.refCode;
        });
    },
    getTotal() {
      axios.get("https://api-wl.dinodapps.com/api/participant").then((res) => {
        this.total = res.data.participant;
      });
    },
    getEntry() {
      axios.get("https://api-wl.dinodapps.com/api/entry").then((res) => {
        this.entry = res.data.entry.count;
      });
    },
    vuecopydemo() {
      this.$refs.text.select();
      document.execCommand("copy");
    },
    handleCopy(result) {
      console.log("onCopy", result);
      this.$toasted.show(
        "Copy Success!, Open On Standar Browser and follow " + result,
        {
          duration: 5000,
          position: "top-center",
          iconPack: "fontawesome",
          icon: "fa-close",
          type: "success",
        }
      );
    },
    handleCopy1(result) {
      console.log("onCopy", result);
      this.$toasted.show(
        "Copy Success!, Open On Standar Browser and follow " + result,
        {
          duration: 5000,
          position: "top-center",
          iconPack: "fontawesome",
          icon: "fa-close",
          type: "success",
        }
      );
    },
    handleCopy2(result) {
      console.log("onCopy", result);
      this.$toasted.show(
        "Copy Success!, Open On Standar Browser and follow " + result,
        {
          duration: 5000,
          position: "top-center",
          iconPack: "fontawesome",
          icon: "fa-close",
          type: "success",
        }
      );
    },
    handleCopy3(result) {
      console.log("onCopy", result);
      this.$toasted.show(
        "Copy Success!, Open On Standar Browser and retweet " + result,
        {
          duration: 5000,
          position: "top-center",
          iconPack: "fontawesome",
          icon: "fa-close",
          type: "success",
        }
      );
    },
  },
};
</script>