<template>
  <div id="app" v-bind:class="{ 'theme-dark': nightMode }">
    <span
      v-if="$isMobile()"
      style="z-index: 99999; position: fixed; margin-left: 84vw"
      class="mr-2 mb-2 fixed-bottom"
    >
      <input type="checkbox" id="theme-toggle" v-model="nightMode" />
      <label for="theme-toggle"><span></span></label>
    </span>
    <span
      v-else
      style="
        z-index: 99999;
        position: absolute;
        margin-left: 92vw;
        margin-top: 22px;
      "
      class="mr-auto"
    >
      <input type="checkbox" id="theme-toggle" v-model="nightMode" />
      <label for="theme-toggle"><span></span></label>
    </span>
    <router-view />
  </div>
</template>

<script>
import "@/assets/darkmode.scss";

export default {
  name: "App",
  watch: {
    nightMode: function () {
      localStorage.setItem(
        "nightMode",
        JSON.stringify({ state: this.nightMode })
      );
      // console.log('Night Mode:' + JSON.stringify(this.nightMode))
    },
  },
  data() {
    return {
      nightMode: false,
      state: null
    };
  },
  mounted() {
    try {
      let state = JSON.parse(localStorage.getItem("nightMode"));
      this.nightMode = state.state;
    } catch (e) {
      console.error(e);
    }
  },
};
</script>
<style>
.input-group-append {
  padding: 0.5rem 1rem;
  border: 1px #cff2ff;
	font-weight: bold;
  background-color: #cff2ff;
}
</style>


