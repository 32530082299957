<template>
  <div id="header">
    <div class="footer-nav-area" id="footerNav" v-if="$isMobile()">
      <div class="container px-0">
        <!-- =================================== -->
        <!-- Paste your Footer Content from here -->
        <!-- =================================== -->
        <!-- Footer Content -->
        <!-- <div class="footer-nav position-relative">
          <ul class="d-flex justify-content-between">
            <li><img src="/images/logo-dark.svg" style="height:25px" class="ml-4 mt-2" alt=""></li>
            <li class="mt-2 float-right text-right">
              <connect-wallet-button />
            </li>
          </ul>
        </div> -->
      </div>
    </div>
    <b-navbar
      v-else
      type="light"
      toggleable="lg"
      class="bg-white text-dark px-5 py-3"
      style="z-index: 1; padding-left: 50px; padding-right: 50px"
    >
      <b-navbar-brand to="/" class="ml-0 ml-lg-5 ml-md-5" style="border: none">
        <img
          src="/images/logo-dark.svg"
          class="ml-4 img-responsive"
          style="height: 30px"
          alt=""
        />
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <!-- Right aligned nav items -->
        <!-- <b-navbar-nav class="ml-auto mr-5 pr-5">
          <b-nav-form>
            <connect-wallet-button />
          </b-nav-form>
        </b-navbar-nav> -->
      </b-collapse>
    </b-navbar>
  </div>
</template>
<script>
import { BNavbar } from "bootstrap-vue";
// import ConnectWalletButton from "./ConnectWalletButton.vue";
import "@/assets/darkmode.scss";
export default {
  components: { BNavbar },
  props: {
    menuColor: {
      require: false,
      default: "#fff",
    },
  },
  name: "Header",
};
</script>
<style scoped>
.bg-custom {
  background-color: #050b25;
}
.footer-nav ul li a .active {
  color: #0d5afd !important;
}
.footer-nav ul li a:active {
  color: #0d5afd !important;
}
.active a {
  color: #0d5afd !important;
}
.router-link-exact-active a {
  color: #0d5afd !important;
}
.footer-nav ul li a .active {
  color: #0d5afd !important;
}
.footer-nav ul li a .router-link-exact-active router-link-active {
  color: #0d5afd !important;
}
.navbar-light .navbar-nav .nav-link {
  color: #282858 !important;
  font-weight: bold;
}
.router-link-exact-active {
  border-bottom: 2px solid #282858;
  color: #282858 !important;
}
.footer-nav ul li a .router-link-exact-active {
  color: #282858 !important;
  fill: #282858 !important;
}
</style>