import Vue from 'vue'
import Vuex from 'vuex'
import Web3 from "web3";
import Abi from '../libraries/Abi'
var bigDecimal = require('js-big-decimal');
Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		wc: {
			web3: null,
			web3Modal: null,
			// library: getLibrary(),
			active: false,
			account: null,
			ethBalance: 0,
			dinoBalance: 0,
			chainId: 0,
			provider: null
		}
	},
	getters: {
		getAccount: (state) => {
			return state.wc.account;
		},
		getEthBalance: (state) => {
			// return state.wc.ethBalance;
			return parseFloat(state.wc.ethBalance).toFixed(5);
		},
		getDinoBalance: (state) => {
			// return state.wc.ethBalance;
			return new bigDecimal(state.wc.dinoBalance);
		},
		getMaxVote: (state) => {
			let total = new bigDecimal(state.wc.dinoBalance);
			let perDino = new bigDecimal(process.env.VUE_APP_VOTE_DIVIDE_BY);
			let max = total.divide(perDino)
			return max;
		},
		getAccountSimple: (state) => {
			if (state.wc.account == null) return null;
			let str = state.wc.account.substring(0, 6) + "..." + state.wc.account.substr(state.wc.account.length - 3);
			return str;
		},
		getWeb3: (state) => {
			return state.wc.web3;
		}
	},
	mutations: {
		setWeb3Modal(state, payload) {
			state.wc.web3Modal = payload
		},
		setAccount(state, account) {
			state.wc.account = account
		}
	},
	actions: {
		async connect({ dispatch, commit, state }) {
			console.log(commit)
			state.wc.provider = await state.wc.web3Modal.connect();

			state.wc.provider.on("accountsChanged", (accounts) => {
				dispatch('fetchAccountData')
				console.log(accounts)
				// this.$store.commit('setAccount', accounts[0])
			});

			// Subscribe to chainId change
			state.wc.provider.on("chainChanged", (chainId) => {
				dispatch('fetchAccountData')
				console.log(chainId);
			});

			// Subscribe to provider connection
			state.wc.provider.on("connect", (info) => {
				dispatch('fetchAccountData')
				// this.$store.commit("setWeb3", this.web3);
				console.log("connect", info);
			});

			// Subscribe to provider disconnection
			state.wc.provider.on("disconnect", (error) => {
				// this.$store.commit("setWeb3", null);
				console.log(error);
			});
			await dispatch('fetchAccountData')
		},
		async fetchAccountData({ state }) {
			// Get a Web3 instance for the wallet
			try {
				state.wc.web3 = new Web3(state.wc.provider);

				const accounts = await state.wc.web3.eth.getAccounts();

				const selectedAccount = accounts[0];
				// alert(selectedAccount)
				state.wc.account = selectedAccount;
				// document.querySelector("#selected-account").textContent = selectedAccount;
				const rowResolvers = accounts.map(async (address) => {
					const balance = await state.wc.web3.eth.getBalance(address);

					const ethBalance = state.wc.web3.utils.fromWei(balance, "ether");
					state.wc.ethBalance = ethBalance;

					let web3Dino = this.getters.getWeb3;
					// alert(process.env.VUE_APP_DINO_ADDRESS)
					let contractDino = await new web3Dino.eth.Contract(Abi.getDino(), process.env.VUE_APP_DINO_ADDRESS);
					// console.log(Abi.getDino())
					let balanceDino = await contractDino.methods.balanceOf(address).call();
					state.wc.dinoBalance = web3Dino.utils.fromWei(balanceDino,'ether');
				});

				await Promise.all(rowResolvers);
			} catch (e) {
				alert('Please select Binance Smart Chain Network')
				// onDisconnect()
				console.error(e)
				// this.$store.dispatch('resetApp')
			}

		},
		async resetApp({ state, commit }) {
			try {
				await state.wc.web3Modal.clearCachedProvider();
			} catch (error) {
				console.error(error)
			}
			commit('setAccount', null)
			commit('setActive', false)
			// commit('setLibrary', getLibrary())
		},
	},
	modules: {},
});