import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index'
Vue.config.productionTip = false
Vue.config.devtools = false;
// import Walletconnect from './libraries/walletconnect'
// Vue.prototype.$WC = new Walletconnect;
import { IconsPlugin } from 'bootstrap-vue'
// Import Bootstrap an BootstrapVue CSS files (order is important)
// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'
// Make BootstrapVue available throughout your project
// Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
import money from 'v-money'
// register directive v-money and component <money>
Vue.use(money, { precision: 4, masked: true })
import VueMobileDetection from "vue-mobile-detection";
Vue.use(VueMobileDetection);
import { SkeletonPlugin } from 'bootstrap-vue'
Vue.use(SkeletonPlugin)
import { ButtonPlugin } from 'bootstrap-vue'
Vue.use(ButtonPlugin)
import { OverlayPlugin } from 'bootstrap-vue'
Vue.use(OverlayPlugin)
import { FormDatepickerPlugin } from 'bootstrap-vue'
Vue.use(FormDatepickerPlugin)
import { ModalPlugin } from 'bootstrap-vue'
Vue.use(ModalPlugin)
import { NavbarPlugin } from 'bootstrap-vue'
Vue.use(NavbarPlugin)
import Toasted from 'vue-toasted';
Vue.use(Toasted)
// import Tawk from 'vue-echo'
// Vue.use(Tawk, {
//     tawkSrc: 'https://embed.tawk.to/60f3a0e2d6e7610a49abcaf2/1farqou9s'
// })
import VueElementLoading from "vue-element-loading";
Vue.component("VueElementLoading", VueElementLoading);
/** Helper */
import Helper from './libraries/Helper';
Vue.prototype.$helper = Helper;
new Vue({
    router,
    render: h => h(App),
    store
}).$mount('#app')