<template>
  <div id="home">
    <Header />
    <banner-whitelist></banner-whitelist>
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import BannerWhitelist from '../components/BannerWhitelist.vue';

export default {
  name: 'Home',
  components: {
    Header,
    BannerWhitelist,
    Footer,
  }
}
</script>
